import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import BpOutOfRangeAll from 'app/dashboard/bpOutOfRange/BpOutOfRangeAll';
import { DashboardTemplate } from 'layouts/dashboard/DashboardTemplate';
import TasksAll from 'app/dashboard/rightSidebar/tasks/TasksAll';
import DueBloodPreasureList from 'app/dashboard/rightSidebar/tasks/DueBloodPreasureList';
import TechnicalIssuesAll from 'app/dashboard/rightSidebar/technicalIssues/TechnicalIssuesAll';
import EverythingGoodAll from 'app/dashboard/rightSidebar/everythingGood/EverythingGoodAll';
import { Authorization } from 'layouts/authorization/Authorization';
import { Loader } from 'components';
import { UserType } from 'models/user';
import TaskInspectionList from 'app/dashboard/rightSidebar/tasks/TaskInspectionList';
import { useAuthState } from 'hooks';
import { Features } from 'models/medic';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRoute } from './AuthorizedRoute';

// const DashboardContainer = lazy(() => import('app/dashboard/DashboardContainer'));
const TableDashboardContainer = lazy(() => import('app/tableDashboard/TableDashboardContainer'));
const LoginContainer = lazy(() => import('app/auth/login/LoginContainer'));
const AdminLoginContainer = lazy(() => import('app/auth/adminLogin/AdminLoginContainer'));
const ResetPasswordContainer = lazy(() => import('app/auth/resetPassword/ResetPasswordContainer'));
const ResetPasswordAppContainer = lazy(() => import('app/auth/resetPassword/ResetPasswordAppContainer'));
const ForgotPasswordContainer = lazy(() => import('app/auth/forgotPassword/ForgotPasswordContainer'));
const SettingsContainer = lazy(() => import('app/settings/SettingsContainer'));
const ContactUsContainer = lazy(() => import('app/settings/contactUs/ContactUsContainer'));
const MembersContainer = lazy(() => import('app/settings/Members/MembersContainer'));
const GeneralContainer = lazy(() => import('app/settings/SettingsGeneral'));
const PrivacyPolicyContainer = lazy(() => import('app/settings/privacyPolicy/PrivacyPolicyContainer'));
const DeleteAccountContainer = lazy(() => import('app/settings/deleteAccount/DeleteAccountContainer'));
const ProfileContainer = lazy(() => import('app/profile/ProfileContainer'));
const PatientsAllContainer = lazy(() => import('app/patients/PatientsAllContainer'));
const DiagnosisOverviewContainer = lazy(() => import('app/diagnosis/DiagnosisOverviewContainer'));
const DiagnosisProfielContainer = lazy(() => import('app/diagnosis/DiagnosisProfileContainer'));
const PatientsContainer = lazy(() => import('app/patients/PatientsContainer'));
const MessagesContainer = lazy(() => import('app/patients/messages/MessagesContainer'));
const ChangePasswordContainer = lazy(() => import('app/changePassword/ChangePasswordContainer'));
const ActivitiesAll = lazy(() => import('app/patients/activities/ActivitiesAll'));
const MedicsContaienr = lazy(() => import('app/medics/MedicsContainer'));
const DevicesContaienr = lazy(() => import('app/devices/DevicesContainer'));
const MedicsDevicesContainer = lazy(() => import('app/medics-devices/MedicsDevicesContainer'));
const ContentContainer = lazy(() => import('app/content/ContentContainer'));
const CreateContentContainer = lazy(() => import('app/content/create/CreateContentContainer'));
const PreviewContentContainer = lazy(() => import('app/content/preview/PreviewContentContainer'));
const ReportListContainer = lazy(() => import('app/reports/ReportListContainer'));
const ReportBillingContainer = lazy(() => import('app/reports/ReportBilling/ReportBillingContainer'));

type SegmentAnalyticsModel = {
  _writeKey: string;
  SNIPPET_VERSION: string;
  track: (eventName: string, properties: Record<string, unknown>, callback?: (error?: Error) => void) => void;
  page: (pageName?: string) => void;
  identify: (userId: string, traits: Record<string, unknown>, options?: Record<string, unknown>) => void;
  load: (writeKey: string) => void;
};

// Segment analytics global variables
declare global {
  interface Window {
    analytics: SegmentAnalyticsModel;
    segmentKey: string;
  }
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}

const AppRoutes = () => {
  const { profile } = useAuthState();
  // Segment setup
  usePageViews();

  const has24hDiagnosisFeature = profile?.hasFeature(Features.DIAGNOSIS_24);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/auth">
          {() => (
            <Authorization>
              <Switch>
                <Route path={AppRoute.login} component={LoginContainer} />
                <Route path={AppRoute.adminLogin} component={AdminLoginContainer} />
                <Route path={AppRoute.forgotPassword} component={ForgotPasswordContainer} />
                <Redirect to={AppRoute.patients} />
              </Switch>
            </Authorization>
          )}
        </Route>
        <Route path={AppRoute.resetPassword} component={ResetPasswordContainer} />
        <Route path={AppRoute.resetPasswordApp} component={ResetPasswordAppContainer} />
        <Route path={`${AppRoute.previewContent}/:contentId`} component={PreviewContentContainer} />

        <Route path="/">
          {() => (
            <DashboardTemplate>
              <Switch>
                {/* Admin routes */}
                <AuthorizedRoute
                  path={AppRoute.medics}
                  exact
                  component={MedicsContaienr}
                  permissions={[UserType.Admin]}
                />
                <AuthorizedRoute
                  path={AppRoute.devices}
                  exact
                  component={DevicesContaienr}
                  permissions={[UserType.Admin]}
                />
                <AuthorizedRoute
                  path={AppRoute.content}
                  exact
                  component={ContentContainer}
                  permissions={[UserType.Admin, UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.createContent}/:contentId?`}
                  exact
                  component={CreateContentContainer}
                  permissions={[UserType.Admin]}
                />
                {/* Medics routes */}
                {/* If / path (home) redirect to /patients */}
                <Redirect to={AppRoute.patients} path={AppRoute.home} exact />;
                <AuthorizedRoute path={AppRoute.openTasks} exact component={TasksAll} permissions={[UserType.Medic]} />
                <AuthorizedRoute
                  path={AppRoute.dueReports}
                  exact
                  component={DueBloodPreasureList}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.taskInspection}
                  exact
                  component={TaskInspectionList}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.technicalIssues}
                  exact
                  component={TechnicalIssuesAll}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.everythingGood}
                  exact
                  component={EverythingGoodAll}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.bpOutOfRange}
                  exact
                  component={BpOutOfRangeAll}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.medicsDevices}
                  exact
                  component={MedicsDevicesContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.home}
                  exact
                  component={TableDashboardContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.settings}
                  exact
                  component={SettingsContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.reports}
                  exact
                  component={ReportListContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.reportsBilling}
                  exact
                  component={ReportBillingContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={AppRoute.allPatients}
                  exact
                  component={PatientsAllContainer}
                  permissions={[UserType.Medic]}
                />
                {has24hDiagnosisFeature && (
                  <AuthorizedRoute
                    path={AppRoute.diagnosis24h}
                    exact
                    component={DiagnosisOverviewContainer} // Candidate for removal
                    permissions={[UserType.Medic]}
                  />
                )}
                {has24hDiagnosisFeature && (
                  <AuthorizedRoute
                    path={`${AppRoute.diagnosis24h}/:patientId`}
                    exact
                    component={DiagnosisProfielContainer} // Candidate for removal
                    permissions={[UserType.Medic]}
                  />
                )}
                <AuthorizedRoute
                  path={`${AppRoute.patients}/:patientId`}
                  exact
                  component={PatientsContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.patients}`}
                  exact
                  component={PatientsContainer}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.activities}/:patientId`}
                  exact
                  component={ActivitiesAll}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.billing}/:patientId`}
                  exact
                  component={() => <PatientsContainer billing={true} />}
                  permissions={[UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.messages}/:patientId`}
                  exact
                  component={() => <MessagesContainer />}
                  permissions={[UserType.Medic]}
                />
                {/* Profile routes */}
                <AuthorizedRoute
                  path={AppRoute.profile}
                  exact
                  component={ProfileContainer}
                  permissions={[UserType.Admin, UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.profile}/change-password`}
                  exact
                  component={ChangePasswordContainer}
                  permissions={[UserType.Admin, UserType.Medic]}
                />
                <AuthorizedRoute
                  path={`${AppRoute.weeklyReport}/:patientId`}
                  exact
                  component={() => <PatientsContainer weeklyReport={true} />}
                  permissions={[UserType.Medic]}
                />
                {/* Settings routes */}
                <SettingsContainer>
                  <AuthorizedRoute
                    path={AppRoute.settingsGeneral}
                    exact
                    component={GeneralContainer}
                    permissions={[UserType.Admin, UserType.Medic]}
                  />
                  <AuthorizedRoute
                    path={AppRoute.settingsMembers}
                    exact
                    component={MembersContainer}
                    permissions={[UserType.Admin, UserType.Medic]}
                  />
                  <AuthorizedRoute
                    path={AppRoute.settingsContactUs}
                    exact
                    component={ContactUsContainer}
                    permissions={[UserType.Admin, UserType.Medic]}
                  />
                  <AuthorizedRoute
                    path={AppRoute.settingsPrivacyPolicy}
                    exact
                    component={PrivacyPolicyContainer}
                    permissions={[UserType.Admin, UserType.Medic]}
                  />
                  <AuthorizedRoute
                    path={AppRoute.settingsDeleteAccount}
                    exact
                    component={DeleteAccountContainer}
                    permissions={[UserType.Admin, UserType.Medic]}
                  />
                </SettingsContainer>
                <Redirect to={AppRoute.patients} />
              </Switch>
            </DashboardTemplate>
          )}
        </Route>
        <Redirect to={AppRoute.login} />
      </Switch>
    </Suspense>
  );
};
export default AppRoutes;
