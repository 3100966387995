import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';

import {
  useAuthState,
  useLocale,
  usePatientState,
  useDeviceState,
  usePatientDispatch,
  useModalDispatch,
  useModalState,
} from 'hooks';
import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { setRecentlyInsertedPatient } from 'context/patients/patientActionCreators/patientActionCreators';
import { AppRoute } from 'app/routing/AppRoute.enum';
import { PatientsCategory } from 'app/patients/patientsSidebar/PatientsSidebar.types';
import { UserStatus } from 'models/user';
import ConfettiStore from 'store/ConfettiStore/ConfettiStore';
import { PatientModel } from 'models/patient';
import { Features } from 'models/medic';

import { AddPatientModal } from './AddPatientModal';
import {
  AddPatientBody,
  AddPatientFormSteps,
  DeliveryOptions,
  DeviceOrderModel,
  DeviceOrderType,
} from './AddPatientModal.types';

export const AddPatientModalContainer = () => {
  const [gender, setGender] = useState<number | null>(null);
  const { user, profile, getUserProfile } = useAuthState();
  const { push } = useHistory();
  const { close } = useModalDispatch();
  const { open: openSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();
  const { getPatients } = usePatientState();
  const dispatch = usePatientDispatch();
  const { processDeviceWithPatient, deviceAssignment } = useDeviceState();
  const { isOpen } = useModalState('add-patient');

  const has24hDiagnosisFeature = !!profile?.hasFeature(Features.DIAGNOSIS_24);

  const initStep = has24hDiagnosisFeature ? AddPatientFormSteps.services : AddPatientFormSteps.basicInfo;

  const [currentStep, setCurrentStep] = useState<AddPatientFormSteps>(initStep);

  const [isSendAppInvite, setSendAppInvite] = useState(false);
  const [isAssignDevice, setAsssignDevice] = useState(false);
  const [isOrderDevice, setOrderDevice] = useState(false);
  const [isEnrolDiagnosis, setEnrolDiagnosis] = useState(false);

  const [{ data, error, loading }, addPatient] = useAxios(
    {
      url: '/patients',
      method: 'POST',
    },
    { manual: true },
  );

  const [, updateMedicsProfile] = useAxios(
    {
      url: `/medics`,
      method: 'POST',
    },
    { manual: true },
  );

  const [{ data: inviteAppPatientData, error: inviteAppError }, inviteAppPatient] = useAxios(
    { url: '/patients/inviteToApp', method: 'POST' },
    { manual: true },
  );

  const [{ data: orderTelemetrieDeviceData, error: orderTelemetrieDeviceError }, orderTelemetrieDevice] = useAxios(
    { url: '/patients/deviceOrder', method: 'POST' },
    { manual: true },
  );

  const [{ data: orderDiagnosisDeviceData, error: orderDiagnosisDeviceError }, orderDiagnosisDevice] = useAxios(
    { url: '/patients/deviceOrder', method: 'POST' },
    { manual: true },
  );

  const [, enrolForDiagnosis] = useAxios(
    {
      method: 'POST',
    },
    { manual: true },
  );

  useEffect(() => {
    if (isOpen) setCurrentStep(initStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!loading && error) {
      const errorMessage = error.response?.data.error || 'error';

      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: `dashboard.snackbar.enroll_new_patient.${errorMessage}_title` }),
        description: formatMessage({ id: `dashboard.snackbar.enroll_new_patient.${errorMessage}_description` }),
        autoHideDuration: 5000,
      });
    }

    if (!loading && !error && data) {
      dispatch(setRecentlyInsertedPatient(data?.data));

      if (getPatients) {
        getPatients();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data]);

  useEffect(() => {
    const isSendAppInviteOk = !isSendAppInvite || (isSendAppInvite && !!inviteAppPatientData);
    const isAssignDeviceOk = !isAssignDevice || (isAssignDevice && !!deviceAssignment?.id);
    const isOrderDeviceOk = !isOrderDevice || (isOrderDevice && !!orderTelemetrieDeviceData);
    const isEnrolDiagnosisOk = !isEnrolDiagnosis || (isEnrolDiagnosis && !!orderDiagnosisDeviceData);

    const allResponsesOk = !!data && isSendAppInviteOk && isAssignDeviceOk && isEnrolDiagnosisOk && isOrderDeviceOk;

    // We check if modal is open to eliminate the behavior when the deviceAssignment triggers the code below. Maybe there is a better way to avoid this problem. I would think of removing entirely the component if the modal is closed
    if (allResponsesOk && isOpen) {
      ConfettiStore.turnOnForDuration({});
      onClose();
      push(`${AppRoute.patients}/${data?.data?.id}?filter=${PatientsCategory.All}`);

      if (!profile?.price_change_acknowledged && getUserProfile) {
        getUserProfile();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, inviteAppPatientData, deviceAssignment, orderTelemetrieDeviceData, orderDiagnosisDeviceData]);

  useEffect(() => {
    if (inviteAppError || orderDiagnosisDeviceError || orderTelemetrieDeviceError) {
      openSnackbar({
        severity: 'error',
        title: formatMessage({ id: `dashboard.snackbar.enroll_new_patient.error.services_title` }),
        description: formatMessage({ id: `dashboard.snackbar.enroll_new_patient.error.services_description` }),
        autoHideDuration: 5000,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteAppError, orderTelemetrieDeviceError, orderDiagnosisDeviceError]);

  const handleGenderChange = (gender: number | string) => setGender(gender as number);
  const resetGender = () => setGender(null);

  const onAddNewPatient = async (values: AddPatientBody) => {
    // Check if the device should be sent to the patient and delivered by PINZON.
    const shouldSendDeviceToPatient =
      values.sendDeviceToPatient && values.selectedDelivery?.value === DeliveryOptions.PINZON;

    // Check if the device should be assigned to the patient by PRAXIS.
    const shouldAssignDeviceToPatient =
      values.sendDeviceToPatient && values.selectedDelivery?.value === DeliveryOptions.PRAXIS && values.devices?.value;

    const isManualBPTreatment =
      values.sendDeviceToPatient && values.selectedDelivery?.value === DeliveryOptions.MANUAL_BP;

    if (shouldAssignDeviceToPatient) setAsssignDevice(!!values.sendDeviceToPatient);

    if (values.send24HDiagnosis) setEnrolDiagnosis(!!values.send24HDiagnosis);

    if (shouldSendDeviceToPatient) setOrderDevice(!!shouldSendDeviceToPatient);

    if (values.sendAppInvite) setSendAppInvite(!!values.sendAppInvite);

    const addData: { [key: string]: unknown } = {
      email: values.email,
      firstname: values.firstName,
      lastname: values.lastName,
      status: UserStatus.Pending,
      date_of_birth: DateTime.fromJSDate(values.birthday as Date).toFormat('yyyy-MM-dd'),
      mobile_no: values.mobileNumber,
      patient_id_vps: values.patientIdPVS,
      remarks: values.remarks,
      medic_id: user?.id,
      updated_by: user?.id,
      country_code: values.countryCode,
      insured_type: values.insuredType?.value,
      insurance_name: values.insuranceName,
      sendAppInvite: values.sendAppInvite,
      street: values.street || null,
      city: values.city || null,
      street_number: values.streetNumber || null,
      postal_code: values.postalCode || null,
      height: Number(values.height) || null,
      weight: Number(values.weight) || null,
      disease_management_programs: values.diseaseManagementPrograms,
      gkv_flatrate: values.gkvFlatrate,
      is_treatment_going_on: checkIfTreatmentIsGoingOn(),
      manual_bp_treatment: isManualBPTreatment,
      sms_reminder: values.smsReminder,
      get_report_autopilot: values.getReportAutopilot,
      assigned_doctor: values.assignedDoctor?.value || null,
      assigned_nurse: values.assignedNurse?.value || null,
    };

    if (gender) addData.gender = gender;

    const addPatientResponse = await addPatient({
      data: addData,
    });

    if (addPatientResponse && addPatientResponse.status === 200) {
      const addedPatient: PatientModel = addPatientResponse.data.data;

      if (values.sendAppInvite) {
        // call send invite app email
        const appInviteResponse = await inviteAppPatient({ data: { id: addedPatient.id } });

        if (appInviteResponse && !inviteAppError && appInviteResponse.status === 200) {
          // Segment events
          window.analytics.track('Send app invite via email', {
            userId: user?.id,
            company: profile?.practice_name,
            fromEnrollPatient: true,
          });
        }
      }

      if (shouldSendDeviceToPatient) {
        const deliveryDate = values.hasDeliveryDateTelemetrie
          ? DateTime.fromJSDate(values.deliveryDateTelemetrie as Date).toFormat('yyyy-MM-dd')
          : null;

        const deviceOrder: DeviceOrderModel = {
          patient_id: addedPatient.id as string,
          company: values.company || null,
          street: values.street,
          number: values.streetNumber,
          postal_code: values.postalCode,
          city: values.city,
          mobile_no: values.mobileNumber as string,
          delivery_date: deliveryDate,
          type: DeviceOrderType.bpTelemonitoring,
        };

        const orderDeviceResponse = await orderTelemetrieDevice({
          data: deviceOrder,
        });

        if (orderDeviceResponse && orderDeviceResponse.status === 200) {
          // Segment events
          window.analytics.track('Device Order Placed - from enroll patient', {
            userId: user?.id,
            company: profile?.practice_name,
            fromEnrollPatient: true,
          });
        }
      }

      if (values.send24HDiagnosis) {
        const deliveryDate = values.hasDeliveryDateDiagnosis
          ? DateTime.fromJSDate(values.deliveryDateDiagnosis as Date).toFormat('yyyy-MM-dd')
          : null;

        const deviceOrder: DeviceOrderModel = {
          patient_id: addedPatient.id as string,
          company: values.company || null,
          street: values.street,
          number: values.streetNumber,
          postal_code: values.postalCode,
          city: values.city,
          mobile_no: values.mobileNumber as string,
          delivery_date: deliveryDate,
          type: DeviceOrderType.bp24Diagnosis,
        };

        const orderDeviceResponse = await orderDiagnosisDevice({
          data: deviceOrder,
        });

        if (!values.hasDeliveryDateDiagnosis) {
          await enrolForDiagnosis({
            url: `/patients/diagnosis/${addedPatient.id}`,
          });
        }

        if (orderDeviceResponse && orderDeviceResponse.status === 200) {
          // Segment events
          window.analytics.track('24h Diagnosis order placed', {
            userId: user?.id,
            company: profile?.practice_name,
          });
        }

        updateMedicsProfile({
          data: {
            id: user?.id,
            price_change_acknowledged: true,
          },
        });
      }

      if (
        values.selectedDelivery?.value === DeliveryOptions.PRAXIS &&
        values.devices?.value &&
        processDeviceWithPatient
      ) {
        processDeviceWithPatient({
          data: {
            ids: [values.devices.value],
            medic_id: user?.id,
            patient_id: addedPatient.id,
          },
        });
      }
    }

    function checkIfTreatmentIsGoingOn() {
      let isTreatmentGoingOn = true;

      // Check conditions for setting `is_treatment_going_on` to false.
      // 1. Telemetrie device is sent to the patient, there's a delivery date for the device, but 24-hour diagnosis isn't sent.
      // 2. 24-hour diagnosis is sent, there's a delivery date for a diagnosis device, but the Telemetrie device isn't sent.
      // 3. Telemetrie device is sent, 24-hour diagnosis device is sent, and both telemetrie and diagnosis delivery dates are set.
      const treatmentShouldNotStartImmediately =
        (shouldSendDeviceToPatient && values.hasDeliveryDateTelemetrie && !values.send24HDiagnosis) ||
        (values.send24HDiagnosis && values.hasDeliveryDateDiagnosis && !values.sendDeviceToPatient) ||
        (shouldSendDeviceToPatient &&
          values.send24HDiagnosis &&
          values.hasDeliveryDateTelemetrie &&
          values.hasDeliveryDateDiagnosis);

      // If any of the above conditions are met, it implies the treatment isn't ongoing.
      if (treatmentShouldNotStartImmediately) {
        isTreatmentGoingOn = false;
      }

      // If we don't send any device to the patient, but selected delivery option for telemonitorings is manual BP, we still need to set the `is_treatment_going_on` to 'true', to activate the treatment from the backend.
      if (isManualBPTreatment) {
        isTreatmentGoingOn = true;
      }

      return isTreatmentGoingOn;
    }
  };

  const onClose = () => {
    close('add-patient');
    resetGender();
  };

  return (
    <AddPatientModal
      onAddNewPatient={onAddNewPatient}
      handleGenderChange={handleGenderChange}
      gender={gender}
      currentStep={currentStep}
      loading={loading}
      setCurrentStep={setCurrentStep}
      onClose={onClose}
    />
  );
};
