import React, { useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik';

import { useAuthState, useLocale, useMembers } from 'hooks';
import { DeliveryOptions } from '../../AddPatientModal.types';
import { Color } from 'utils/colors.enum';
import Alert from 'components/Alert/Alert';
import { InternationlNumber, SelectField } from 'components';
import { Features } from 'models/medic';
import { SelectOption } from 'components/Select/Select.types';

interface FormikType {
  remarks: string;
  termsAndConditionsConfirmation: boolean;
  sendAppInvite: boolean;
  email: boolean;
  mobileNumber: string;
  send24HDiagnosis: boolean;
  getReportAutopilot: boolean;
  selectedDelivery: {
    label: string;
    value: DeliveryOptions;
  };
  assignedDoctor: { label: string; value: string };
  assignedNurse: { label: string; value: string };
}

export type MemberModel = {
  id: string;
  id_auto: number;
  email: string;
  fullname: string;
  firstname: string;
  lastname: string;
  mobile_no: string | null;
  address: string | null;
  status: number;
  hashedEmail: string;
  medic_id: string;
  role: number;
  position_type: string | null;
};

export const filterMembersByType = (members: MemberModel[], role: string): MemberModel[] => {
  return members.filter((member) => member.position_type === role);
};

export const mapMemberDoctorsToSelectOptions = (members: MemberModel[]): SelectOption[] => {
  return filterMembersByType(members, 'Physician').map((member) => {
    return {
      value: member.id,
      label: `${member.fullname} - ${member.email}`,
    };
  });
};

export const mapMemberNursesToSelectOptions = (members: MemberModel[]): SelectOption[] => {
  return filterMembersByType(members, 'Medical Nurse').map((member) => {
    return {
      value: member.id,
      label: member.fullname,
    };
  });
};

export const RemarksInfoStep = () => {
  const { formatMessage } = useLocale();
  const { errors, values, setFieldValue } = useFormikContext<FormikType>();
  const { selectedDelivery, getReportAutopilot, sendAppInvite } = values;
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const { profile } = useAuthState();
  const { members } = useMembers();

  const hasAssignedDoctorAndNurseFeature = profile?.hasFeature(Features.ASSIGNED_DOCTOR_AND_NURSE);
  const hasSendAppInviteEnrollmentFeature = !profile?.hasFeature(Features.DISABLE_SEND_APP_INVITE_ENROLLMENT);

  useEffect(() => {
    if (selectedDelivery && selectedDelivery.value === DeliveryOptions.MANUAL_BP) {
      setFieldValue('sendAppInvite', true);

      if (checkboxRef.current !== null) {
        checkboxRef.current.disabled = true;
        checkboxRef.current.style.color = Color.mediumGray;
      }
    }
  }, [selectedDelivery, setFieldValue]);

  return (
    <div>
      {hasAssignedDoctorAndNurseFeature && (
        <>
          <SelectField
            name="assignedDoctor"
            options={mapMemberDoctorsToSelectOptions(members)}
            labelId="enroll_new_patient.assigned_specialist_email"
            values={values.assignedDoctor}
            isMulti={false}
            placeholderId="enroll_new_patient.assigned_specialist_email"
            className="pii"
            marginTop={3}
            paddingR={0}
            required={true}
            isSearchable={false}
            isError={!!errors.assignedDoctor}
          />
          <SelectField
            name="assignedNurse"
            options={mapMemberNursesToSelectOptions(members)}
            labelId="enroll_new_patient.assigned_nurse"
            values={values.assignedNurse}
            isMulti={false}
            placeholderId="enroll_new_patient.assigned_nurse"
            className="pii"
            marginTop={3}
            paddingR={0}
            required
            isSearchable={false}
            isError={!!errors.assignedNurse}
          />
        </>
      )}

      <label htmlFor="mobileNumber" className="mt-3 block text-base text-gray-light">
        <span>{formatMessage({ id: 'enroll_new_patient.mobile_number_optional' })}</span>
        <InternationlNumber
          name="mobileNumber"
          dataTestId="e2e-enroll-mobile"
          isError={!!errors.mobileNumber}
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.mobileNumber ? 'red' : 'gray-light'} ${
            errors.mobileNumber && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          } text-gray placeholder-gray-light rounded-md pii`}
        />
      </label>

      <label className="flex mt-5 items-center">
        <input
          type="checkbox"
          className={`form-checkbox rounded h-5 w-5  focus:outline-none border-${
            errors.getReportAutopilot ? 'red' : 'gray-light'
          } ${errors.getReportAutopilot && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'}`}
          style={{ color: Color.cyan }}
          name="getReportAutopilot"
          checked={getReportAutopilot}
          onChange={(e) => setFieldValue('getReportAutopilot', e.target.checked)}
        />
        <span className="text-base text-justify	items-center ml-2 text-gray">
          {formatMessage({
            id: `enroll_new_patient.checkbox.label.get_report_autopilot${
              hasAssignedDoctorAndNurseFeature ? '.doctor' : ''
            }`,
          })}
        </span>
      </label>

      {hasSendAppInviteEnrollmentFeature && (
        <label className="flex mt-5 items-center">
          <input
            ref={checkboxRef}
            type="checkbox"
            className={`form-checkbox rounded h-5 w-5  focus:outline-none border-${
              errors.sendAppInvite ? 'red' : 'gray-light'
            } ${errors.sendAppInvite && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'}`}
            style={{ color: Color.cyan }}
            name="sendAppInvite"
            checked={values.sendAppInvite}
            onChange={(e) => setFieldValue('sendAppInvite', e.target.checked)}
          />
          <span className="text-base text-justify	items-center ml-2 text-gray">
            {formatMessage({ id: 'enroll_new_patient.send_app_invite' })}
          </span>
        </label>
      )}

      {selectedDelivery && selectedDelivery.value === DeliveryOptions.MANUAL_BP && (
        <div className="max-w-md mx-auto mt-2 mb-4">
          <Alert text={formatMessage({ id: 'enroll_new_patient.step.delivery.manual_bp.warning' })} />
        </div>
      )}

      <label htmlFor="email" className="block text-base text-gray-light mt-3">
        <span>{formatMessage({ id: 'enroll_new_patient.email' })}</span>
        {(sendAppInvite || getReportAutopilot) && <span className="text-red-dark ml-1">{'*'}</span>}
        <div className="mt-1">{formatMessage({ id: 'enroll_new_patient.email_note' })}</div>
        <Field
          type="email"
          data-testid="e2e-enroll-email"
          name="email"
          required={values?.sendAppInvite}
          className={`mt-1 shadow-sm block w-full sm:text-sm border-${errors.email ? 'red' : 'gray-light'} ${
            errors.email && 'focus:border-red-dark focus:ring-offset-red-dark focus:ring-red-dark'
          } text-gray placeholder-gray-light rounded-md pii`}
          placeholder="Email-Adresse"
        />
      </label>

      <label htmlFor="remarks" className="block text-base text-gray-light mt-3">
        <span>{formatMessage({ id: 'enroll_new_patient.step.remarks' })}</span>
        <Field
          as="textarea"
          data-testid="e2e-enroll-remarks"
          name="remarks"
          className="mt-1 shadow-sm block w-full sm:text-sm border-gray-light text-gray placeholder-gray-light rounded-md pii"
          placeholder="Text"
        />
      </label>

      <label className="flex mt-10">
        <input
          type="checkbox"
          className={`form-checkbox rounded h-5 w-5 focus:outline-none border-${
            errors.termsAndConditionsConfirmation ? 'red' : 'gray-light'
          } ${errors.termsAndConditionsConfirmation && 'focus:border-red-dark  focus:ring-red-dark'}`}
          style={{ color: Color.cyan }}
          name="termsAndConditionsConfirmation"
          checked={values.termsAndConditionsConfirmation}
          onChange={(e) => setFieldValue('termsAndConditionsConfirmation', e.target.checked)}
        />
        <span className="text-base text-justify	items-center ml-2 text-gray">
          {formatMessage({ id: 'enroll_new_patient.terms_and_conditions' })} <span className="text-red-dark">*</span>
        </span>
      </label>
    </div>
  );
};
