import React, { useEffect, useReducer } from 'react';
import useAxios from 'axios-hooks';

import { authStorage } from '../authStorage/AuthStorage';
import { AuthDispatchContext, AuthStateContext } from 'context/auth/authContext/AuthContext';
import { authReducer } from 'context/auth/authReducer/authReducer';
import { setProfile, setProfileLoading } from '../authActionCreators/authActionCreators';
import { UserType } from 'models/user';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthorized: !!authStorage.accessToken,
    isAuthorizing: !!authStorage.accessToken,
    user: undefined,
    role: authStorage.role,
    accessToken: authStorage.accessToken,
    isMember: authStorage.member,
    isGhostLogin: Boolean(authStorage.ghostLogin),
    isOwner: authStorage.owner,
    profile: undefined,
    profileLoading: false,
  });

  const { role, user, isMember } = state;

  const [{ data, loading, error }, getProfile] = useAxios({ method: 'GET' });

  const getUserProfile = () => {
    getProfile({
      url: `/${
        role === UserType.Admin
          ? `admins/${user?.id}`
          : isMember
          ? `members/${user?.id}/${user?.memberId}`
          : `medics/${user?.id}`
      }`,
    });
  };

  useEffect(() => {
    if (user) getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (loading) dispatch(setProfileLoading(true));

    if (data && !loading) {
      dispatch(setProfile(data?.data));

      if (data?.data) {
        const profile = data.data;

        try {
          // Disable Segment in case of a non test/customer client
          if (['CUSTOMER', 'TEST'].includes(profile.account_type)) {
            window.analytics._writeKey = window.segmentKey;
            window.analytics.SNIPPET_VERSION = '4.15.3';
            window.analytics.load(window.segmentKey);
            window.analytics.page();
          }
        } catch (e) {
          // in case the script is loaded, can't and don't need to re-load it
          // just continue, the 'identity' call will be made (as well as the rest of the calls)
        }

        window.analytics.identify(profile.id, {
          firstName: profile.firstname,
          lastName: profile.lastname,
          email: profile.email,
          plan: profile.account_type,
          company: profile.practice_name,
          logins: 5,
        });

        // fire a session start event if not fired
        if (window.sessionStorage.getItem('segment_session_fired') !== 'true') {
          window.analytics.track('Session Start', {
            firstName: profile.firstname,
            lastName: profile.lastname,
            email: profile.email,
            company: profile.practice_name,
          });
          window.sessionStorage.setItem('segment_session_fired', 'true');
        }
      }
      dispatch(setProfileLoading(false));
    }

    if (error && !loading) dispatch(setProfileLoading(false));
  }, [data, loading, error]);

  return (
    <AuthStateContext.Provider value={{ ...state, getUserProfile }}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
