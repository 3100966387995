import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import SideBar from 'components/SideBar/SideBar';
import BankNotesIcon from 'components/Icons/BankNotesIcon';
import { AppRoute } from 'app/routing/AppRoute.enum';
import GearIcon from 'components/Icons/GearIcon';
import UserListIcon from 'components/Icons/UserListIcon';
import BpIcon from 'components/Icons/BpIcon';
import ContentIcon from 'components/Icons/ContentIcon';
import UserPlusIcon from 'components/Icons/UserPlusIcon';
import EuroIcon from 'components/Icons/EuroIcon';
import { useAuthState, useLocale, useModalDispatch } from 'hooks';
import { UserType } from 'models/user';
import RemoteDeviceIcon from 'components/Icons/RemoteDeviceIcon';
import Clock24hIcon from 'components/Icons/Clock24hIcon';
import { OnboardingModeStore } from 'store/OnboardingModeStore/OnboardingModeStore';
import { Features } from 'models/medic';

import { DashboardTemplateProps } from './DashboardTemplate.types';

export const DashboardTemplate = observer(({ children }: DashboardTemplateProps) => {
  const { pathname } = useLocation();
  const { open } = useModalDispatch();
  const { user, role, profile, isMember } = useAuthState();
  const { formatMessage } = useLocale();

  const isOnDashboard = pathname === AppRoute.home;
  const isOnPatientProfile =
    pathname.startsWith(`${AppRoute.patients}/`) || pathname.startsWith(`${AppRoute.weeklyReport}`);
  const isOnAllPatients = pathname.startsWith(AppRoute.allPatients);
  const isOnDiagnosis24h = pathname.startsWith(AppRoute.diagnosis24h);
  const isOnMedicsDevices = pathname === AppRoute.medicsDevices;
  const isSettings = pathname.startsWith(AppRoute.settings);
  const isOnMedics = pathname === AppRoute.medics;
  const isOnDevices = pathname === AppRoute.devices;
  const isOnContent = pathname === AppRoute.content;
  const isOnReports = pathname.startsWith(AppRoute.reports);

  let has24hDiagnosisFeature = !!profile?.hasFeature(Features.DIAGNOSIS_24);

  if (OnboardingModeStore.isOn) {
    has24hDiagnosisFeature = false;
  }

  // hide Home for everyone
  const hideHome = true;
  // quick and dirty for Kai. Tech deby
  // const hideHome = user?.id === '5c12f127-d57f-46c9-bf37-4b29ec854150';

  const sideBarTopItems =
    role === UserType.Admin
      ? [
          {
            icon: <UserListIcon />,
            tooltip: formatMessage({ id: 'common.medics' }),
            path: AppRoute.medics,
            isSelected: isOnMedics,
            testId: 'e2e-medics-list-btn',
            id: 'sidebar-medics-list-btn',
          },
          {
            icon: <RemoteDeviceIcon />,
            tooltip: formatMessage({ id: 'common.devices' }),
            path: AppRoute.devices,
            isSelected: isOnDevices,
            testId: 'e2e-devices-list-btn',
            id: 'sidebar-devices-list-btn',
          },
          {
            icon: <UserPlusIcon />,
            tooltip: formatMessage({ id: 'common.enroll_medic' }),
            testId: 'e2e-enroll-medic-btn',
            id: 'sidebar-enroll-medic-btn',
            onClick: () => open('add-medic'),
          },
          {
            icon: <ContentIcon />,
            tooltip: formatMessage({ id: 'common.content' }),
            path: AppRoute.content,
            isSelected: isOnContent,
            testId: 'e2e-content-btn',
            id: 'sidebar-content-btn',
            environments: ['development', 'staging'],
          },
        ]
      : [
          {
            icon: <BpIcon />,
            tooltip: 'Blood Pressure',
            path: AppRoute.patients,
            isSelected: isOnPatientProfile,
            testId: 'e2e-blood-pressure-bp-btn',
            id: 'sidebar-blood-pressure-bp-btn',
          },
          {
            icon: <UserPlusIcon />,
            tooltip: 'Enroll Patient',
            testId: 'e2e-enroll-patient-btn',
            id: 'sidebar-enroll-patient-btn',
            onClick: () => {
              open('add-patient');
            },
          },
          {
            icon: <UserListIcon />,
            tooltip: 'Users',
            path: AppRoute.allPatients,
            isSelected: isOnAllPatients,
            testId: 'e2e-patients-bp-btn',
            id: 'sidebar-patients-bp-btn',
          },
          {
            icon: <RemoteDeviceIcon />,
            tooltip: formatMessage({ id: 'common.devices' }),
            path: AppRoute.medicsDevices,
            isSelected: isOnMedicsDevices,
            testId: 'e2e-devices-list-btn',
            id: 'sidebar-devices-list-btn',
          },
          ...(has24hDiagnosisFeature
            ? [
                {
                  icon: <Clock24hIcon />,
                  tooltip: 'Diagnosis',
                  path: AppRoute.diagnosis24h,
                  isSelected: isOnDiagnosis24h,
                  testId: 'e2e-diagnosis-24h-btn',
                  id: 'sidebar-diagnosis-24h-btn',
                },
              ]
            : []),
          ...(hideHome
            ? []
            : [
                {
                  icon: <BankNotesIcon />,
                  tooltip: 'Home',
                  path: AppRoute.home,
                  isSelected: isOnDashboard,
                  testId: 'e2e-home-btn',
                  id: 'sidebar-home-btn',
                },
              ]),
          {
            icon: <ContentIcon />,
            tooltip: formatMessage({ id: 'common.content' }),
            path: AppRoute.content,
            isSelected: isOnContent,
            testId: 'e2e-content-btn',
            id: 'sidebar-content-btn',
            environments: ['development', 'staging'],
          },
        ];

  return (
    <>
      <SideBar
        firstname={user?.firstname}
        lastname={user?.lastname}
        username={profile?.practice_name}
        topItems={sideBarTopItems}
        bottomItems={[
          ...(role === UserType.Admin || OnboardingModeStore.isOn
            ? []
            : [
                {
                  icon: <EuroIcon />,
                  tooltip: 'Reports',
                  path: AppRoute.reportsBilling,
                  isSelected: isOnReports,
                  testId: 'e2e-billing-report-btn',
                  id: 'sidebar-billing-report-btn',
                },
              ]),
          {
            icon: <GearIcon />,
            tooltip: 'Settings',
            path: isMember ? AppRoute.settingsMembers : AppRoute.settingsGeneral,
            isSelected: isSettings,
            testId: 'e2e-settings-btn',
            id: 'sidebar-settings-btn',
          },
        ]}
      >
        {children}
      </SideBar>
    </>
  );
});
